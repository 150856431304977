import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
	{
		path: "core",
		loadChildren: () => import("./core/core.module").then((m) => m.CoreModule),
	},
  {
    path: 'pages',
		loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: '',
    pathMatch:'full',
    redirectTo: 'core/home'
  }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
